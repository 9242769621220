<template lang="pug">
  article.collection-index(@click="searchVisible = false")

    //- (collection entry overlay)
    transition(name="collection-item", @after-enter="lockScroll(true)", @leave="lockScroll(false)")
      .fixed.z-50.overlay.bg-white.text-white(v-show="$route.name === 'collection-item'")
        //- collection item overlay
        transition(name="collection-item")
          router-view(:key="$route.path", ref="overlay")

        //- next button
        button.absolute.z-30.top-0.h-full.right-0.px-20.lg_px-40.blend-difference(@click="$refs.overlay && $refs.overlay.next()")
          svg-chevron-right.h-40
        //- prev button
        button.absolute.z-30.top-0.h-full.left-0.px-20.lg_px-40.blend-difference.group(@click="$refs.overlay && $refs.overlay.prev()")
          svg-chevron-left.h-40.md_group-hover_opacity-100.md_opacity-0.transition.duration-150

        //- close button
        button.absolute.z-30.top-0.right-0.p-20.pt-30.lg_p-40.blend-difference(@click="$refs.overlay.close()")
          svg-x

    animate-push-on-panel
      //- intro
      section.px-20.pt-30.mb-25.md_p-40.md_-mb-20(v-if="doc && doc.intro")
        .textbody.w-full.md_w-8x12.text-14.md_text-20.xl_text-24(v-html="doc.intro", :class="{'collection-index__intro--less': !introMore}")
        btn-read-more.mt-30(@click.native="introMore = !introMore")

      //- index
      section.flex.flex-wrap.px-10.md_px-20
        //- (search artists)
        nav.relative.w-full.text-14.md_text-18.h-30.md_h-0(ref="searchPanel")
          .relative.md_absolute.z-10.top-0.left-0.w-full.md_w-7x12.px-10.md_px-20.md_pt-60.pb-10.md_pb-0
            .heading
              h2.text-20.md_text-24 Explore our collection highlights
          .relative.md_absolute.z-10.top-0.right-0.w-full.md_w-5x12.px-10.md_px-20.md_pt-60
            .md_w-full_80.bg-white.md_-ml-40.md_px-40
              .flex.w-1x2.lg_w-4x5.lg_pr-10.justify-start.items-center.leading-none
                //- (open search btn)
                button.h-30.flex.w-full.items-center.border-b.border-current.pb-2(@click.stop="searchVisible = true", v-show="!searchVisible && !filterBy") Search Artists...
                //- (clear search btn)
                button.h-30.flex.w-full.items-center.justify-end.whitespace-no-wrap(@click.stop="clearSearch", v-show="filterBy")
                  | Filter by "{{ this.filterBy }}"
                  svg-x.w-17.h-14.ml-15
                //- (input)
                form.w-full.flex.items-center(v-show="searchVisible", @submit.prevent="search(query)", validate)
                  .flex-1.mr-5.md_mr-15.h-30.flex.items-center.border-b.border-current
                    label.sr-only Search
                    input.w-full(ref="input", v-model="query", required, placeholder="Search...", @keydown="onKeyWhenSeaching")
                  button.order-last.p-6.-mr-6.co(@click="searchVisible = false", type="button")
                    svg-x.w-17.h-14
                  button.sr-only(type="submit") Submit

              ul.bg-white.py-30(v-show="searchVisible")
                li(v-for="artist in artistsListFiltered")
                  button.focus_text-teal.hover_text-teal.text-left(@click="search(artist)", @keydown="onKeyWhenSeaching") {{ artist }}
        .sep.h-50.md_h-30 &nbsp;
        //- entries...
        //- pre.bg-black.text-white.z-50.relative {{ collectionFiltered }}
        template(v-for="(entry, i) in collectionFiltered")
          //- view loans CTA
          router-link.block.bg-teal.w-screen.-mx-10.md_-mx-20.min-h-130.md_min-h-16vw.flex.items-center.justify-center.text-18.md_text-20.xl_text-24(v-if="lastNameLetter(i) === 'C' && lastNameLetter(i) !== lastNameLetter(i - 1)", to="/collection/loans") View our loans

          //- letter separator ?
          //- .block.w-full.bg-black.text-white
            pre {{ lastNameLetter(i)}} !== {{ lastNameLetter(i - 1) }}
            pre {{  lastNameLetter(i) !== lastNameLetter(i - 1) }}
          h2.w-full.px-10.md_px-20.uppercase.mt-40.mb-10.md_mt-80.md_mb-30.text-36.xl_text-60(v-if="lastNameLetter(i) !== lastNameLetter(i - 1)", :class="{'invisible': filterBy}") {{ lastNameLetter(i) }}
          //- entry
          collection-item-thumb.mb-24.md_mb-60(:entry="entry", :key="entry.slug")

      footer
        small.block.text-12.xl_text-14.px-20.md_px-40.pb-30.mt-160 All artwork images are © The Artist unless noted otherwise
</template>

<script>
import { mapState } from 'vuex'
import CollectionItemThumb from '@/components/collection/CollectionItemThumb'
export default {
  name: 'CollectionIndex',
  data () {
    return {
      doc: null,
      introMore: false,
      searchVisible: false,
      query: '',
      filterBy: null
    }
  },
  computed: {
    ...mapState(['collection']),
    artistsListFiltered () {
      const uniqueNames = [...new Set(this.collection.map(entry => entry.artist))]
      return uniqueNames
        .filter(name => name.toLowerCase().includes(this.query.toLowerCase()))
    },
    collectionFiltered () {
      return this.collection
        .filter(entry => !this.filterBy || entry.artist === this.filterBy || entry.artist.toLowerCase().includes(this.filterBy.toLowerCase()))
    }
  },
  methods: {
    lastNameLetter (i) {
      return this.collection[i]?.artistSort?.slice(0, 1)[0]?.toLowerCase()
    },
    lockScroll (lock) {
      document.body.style.overflow = lock ? 'hidden' : ''
    },
    async getDoc () {
      this.doc = await this.$store.dispatch('getSingle', 'collectionIndex')
    },
    search (term) {
      this.filterBy = term
      this.searchVisible = false
      // close on escape key
      const onKey = e => {
        if (e.key === 'Escape') {
          this.clearSearch()
          window.removeEventListener('keydown', onKey)
        }
      }
      window.addEventListener('keydown', onKey)
    },
    clearSearch () {
      this.filterBy = null
      this.query = ''
    },
    onKeyWhenSeaching (e) {
      if (e.keyCode === 38 || e.keyCode === 40) {
        e.preventDefault()
        const focusEls = Array.from(this.$refs.searchPanel.querySelectorAll('input, ul button'))
        let i = focusEls.indexOf(document.activeElement)
        i = e.keyCode === 40 ? i + 1 // down
          : e.keyCode === 38 ? i - 1 // up
            : -1
        if (focusEls[i]) focusEls[i].focus()
      }
    }
  },
  created () {
    this.getDoc()
    this.$store.dispatch('getCollection')
    // lock scroll if entering on item (transition doesn't catch)
    this.lockScroll(this.$route.name === 'collection-item')
  },
  watch: {
    searchVisible (vis) {
      if (vis) this.$nextTick(() => this.$refs.input.focus())
    }
  },
  metaInfo () {
    const title = 'Collection'
    return {
      title,
      meta: this.$store.getters.meta({
        title,
        descrip: this.doc?.metaDescription,
        img: this.doc?.metaImage[0]?.url
      })
    }
  },
  components: { CollectionItemThumb }
}
</script>

<style>
.collection-item-leave-active{
  transition: opacity 500ms;
}
.collection-item-enter-active{
  transition: opacity 500ms;
}
.collection-item-enter,.collection-item-leave-to{
  opacity:0;
}

.collection-index__intro--less > *:nth-child(n + 2) {
  display: none;
}
</style>
