<template lang="pug">
  article.collection
    //- red sidebar
    panel-left(ref="panel", :keepOpen="!navCollapsed", bg="bg-pink")
      ul
        li.mb-20
          router-link.btn-theme-text(to="/collection") Artists
        li
          router-link.btn-theme-text(to="/collection/loans") Loans
        li
          router-link.btn-theme-text(to="/collection/loans/borrow") Borrow

    //- (filter btn mbl)
    button.h-60.px-20.flex.w-full.items-center.md_hidden.focus_outline-none(@click="openPanel") Expand Menu

    //- child pages
    transition(name="pagefade", @after-leave="$root.$emit('afterRouteLeave')")
      router-view(:key="$route.params.page || $route.meta.group || $route.name")
</template>

<script>
let timer
export default {
  name: 'Collection',
  data () {
    return {
      navCollapsed: true
    }
  },
  methods: {
    openPanel () {
      return this.$refs.panel?.open()
    },
    setPanelInitial () {
      if (this.$store.getters.bkpt === 'md' && this.$route.name === 'collection') {
        clearTimeout(timer)
        timer = setTimeout(() => {
          this.openPanel()
          window.addEventListener('scroll', () => this.$refs.panel.close(), { once: true })
        }, 500)
      }
    }
  },
  mounted () {
    this.setPanelInitial()
  },
  watch: {
    '$route' (to, from) {
      if (!from.name?.includes('collection')) {
        this.setPanelInitial()
      }
    }
  }
}
</script>

<style>
</style>
