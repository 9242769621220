<template lang="pug">
  figure.collection-item-slide.absolute.overlay
    //- .flex to fill parent
    .absolute.overlay.flex(:class="format.frame")
      //- inner
      .relative(:class="format.inner")
        //- (video)
        template(v-if="isVideo")
          //- just fills as new overlay
          .absolute.overlay.bg-white.flex.items-center.justify-center.px-20
            .px-20.w-full.lg_w-10x12.mx-auto
              video-plyr-js(:video="slide.videoAsset && slide.videoAsset[0]", :embed="slide.vimeoYoutubeEmbed")
        //- (image)
        template(v-else-if="image")
          resp-img.transition.duration-150.pointer-events-none(:bg="true", :image="image", @contextmenu.prevent, @load="onImgLoad", :class="{'opacity-0': !naturalW}", :fit="format.imgFit")

    //- caption
    figcaption.absolute.bottom-0.left-0.w-full.flex.justify-end.text-12.xl_text-14.text-black(:class="{'md_text-white': slide.captionColor === 'white'}")
      .w-full.px-20.pb-20.lg_pr-40(v-if="slide.captionType !== false", :class="slide.captionWidth === 'narrow' ? 'lg_w-2x12' : 'lg_w-3x12'")

        //- (slide custom caption)
        template(v-if="slide.captionType === 'custom'")
          p(v-html="slide.captionCustom")

        //- (asset caption)
        template(v-else-if="slide.captionType === 'asset'")
          p(v-html="image.assetCaption")

        //- (entry/asset combo (if custom slides/assets))
        template(v-else-if="slide.captionType")
          p
            | {{ entry.artist }}<br>
            | <i>{{entry.title}}</i>{{entry.year.length ? ', ' + entry.year : ''}}
            template(v-if="entry.medium.length") <br>{{ entry.medium }}
            template(v-if="entry.dimensions.length")
              | <br>{{ entry.dimensions.replaceAll('x', '×') }}

        //- (default (artlogic) caption)
        template(v-else)
          p
            | {{ entry.artist }}<br>
            | <i>{{entry.title}}</i>{{entry.year.length ? ', ' + entry.year : ''}}
            template(v-if="entry.medium.length") <br>{{ entry.medium }}
            template(v-if="entry.dimensions.length")
              | <br>{{ entry.dimensions.replaceAll('x', '×') }}

        //- (related content)
        flex-link.flex.mt-20.items-start(v-if="slide.relatedContentFlexLink.url", :link="slide.relatedContentFlexLink")
          small.block.mr-10.text-9.xl_text-10.shadow-underline.leading-relaxed(v-if="slide.relatedContentLabel") {{ slide.relatedContentLabel }}
          | {{ slide.relatedContentFlexLink.customText }}

        //- crediting
        small.block.text-10.mt-15(:data-type="slide.captionType")
          //- (asset credits)
          template(v-if="slide.captionType && hasCrediting(image)")
            div(v-if="getCourtesy(image)") {{ getCourtesy(image) }}
            div(v-if="image.copyright && image.copyright.length") ©{{ image.copyright }}
            div(v-if="image.photoCredit && image.photoCredit.length") Photo: {{ image.photoCredit }}
          //- (entry credits)
          template(v-else-if="hasCrediting(entry)")
            div(v-if="getCourtesy(entry)") {{ getCourtesy(entry) }}
            div(v-if="entry.copyright && entry.copyright.length") ©{{ entry.copyright }}
            div(v-if="entry.photoCredit && entry.photoCredit.length") Photo: {{ entry.photoCredit }}

</template>

<script>
export default {
  name: 'CollectionItemSlide',
  props: ['slide', 'entry'],
  data () {
    return {
      naturalW: 0,
      naturalH: 0
    }
  },
  computed: {
    image () {
      return this.slide.image[0] || {}
    },
    isVideo () {
      return (this.slide?.videoAsset && this.slide.videoAsset[0]?.url) || this.slide?.vimeoYoutubeEmbed
    },
    copyright () {
      const txt = this.image.copyright || this.slide?.videoAsset?.copyright || this.entry?.copyright
      return txt && txt.replace('Copyright', '')
    },
    format () {
      const defaultFit = 'h100_left'
      let format = this.slide.imageFit

      // gray bg on fit-left-landscape ?
      // const imgLnd = this.image?.width ? this.image.width > this.image.height * 1.1 : this.naturalW > this.naturalH * 1.1
      // const bgcolor = imgLnd && format === 'h100_left' ? 'bg-gray-100' : ''

      // formats
      const formats = {
        // "fit left"
        h100_left: {
          frame: 'justify-start',
          inner: 'w-full lg_max-w-9x12 lg_pr-20', // ${bgcolor}`,
          imgFit: 'object-contain object-center md_object-left'
        },
        // "portrait, fit left (narrow caption)"
        h100_left_wide: {
          frame: 'lg_max-w-10x12 lg_pr-20',
          imgFit: 'object-contain object-center md_object-left'
        },
        // "portrait, cover left"
        w50_cover_left: {
          frame: 'justify-start',
          inner: 'w-full lg_max-w-6x12',
          imgFit: 'object-contain lg_object-cover object-center'
        },
        // "landscape, fit center"
        // inset_centered: {
        //   frame: 'pt-125 pb-140 xl_pt-175 xl_pb-175 justify-center lg_px-40',
        //   inner: 'w-full lg_max-w-10x12',
        //   imgFit: 'object-contain object-center'
        // },
        // "landscape, cover screen"
        cover_centered: {
          frame: '',
          inner: 'w-full',
          imgFit: 'object-contain md_object-cover object-center'
        }
      }
      // narrow or wide format?
      format += this.slide.captionWidth === 'narrow' ? '_wide' : ''
      // return format body
      return formats[format] || formats[defaultFit]
    }
  },
  methods: {
    onImgLoad (e) {
      this.naturalH = e.target.naturalHeight
      this.naturalW = e.target.naturalWidth
    },
    hasCrediting (doc) {
      const text = doc.copyright || doc.courtesy || doc.photoCredit || ''
      return text.length > 0
    },
    getCourtesy ({ courtesy }) {
      let str = courtesy || ''
      const ignore = 'Roberts Collection'.toLowerCase()
      str = str.replace('Courtesy ', '')
      return str.length && !str.toLowerCase().includes(ignore) ? `Courtesy ${str}`
        : null
    }
  }
}
</script>

<style>
</style>
