<template lang="pug">
  article.collection-item.absolute.overlay.bg-white.focus_outline-none(tabindex="0")
    transition-group(name="collection-item")
      //- slides...
      collection-item-slide(v-for="(slide, i) in slides", v-show="i === current", :key="slide.id", :slide="slide", :entry="entry")
    //- counter
    .absolute.bottom-0.right-0.lg_right-auto.lg_left-0.p-20.lg_p-40.text-12.xl_text-14.z-10.text-white.blend-difference(v-if="slides.length > 1", :class="{'md_text-white': uiColor === 'white'}") {{ current + 1}}/{{ slides.length }}
</template>

<script>
import CollectionItemSlide from '@/components/collection/CollectionItemSlide'
import throttle from 'lodash/throttle'
let lastRt
export default {
  name: 'CollectionItem',
  data () {
    return {
      current: 0
    }
  },
  computed: {
    slug () {
      return this.$route.params.collectionItem
    },
    entry () {
      return this.$store.state.collection.find(entry => entry.slug === this.slug)
    },
    artlogicSlides () {
      let slides
      if (this.entry) {
        let urls = []
        // main image first slide
        const main = this.entry.mainImageUrlLarge
        if (main) urls.push(main)
        // secondary images...
        const secondary = this.entry.secondaryImagesUrlsLarge?.split(';')
        if (secondary) secondary.forEach(url => urls.push(url))
        // format as objects
        urls = urls.map(url => ({ image: [{ url }] }))
        slides = urls.length && urls
      }
      return slides
    },
    slides () {
      return (this.entry?.slides?.length && this.entry.slides) || this.artlogicSlides || []
    },
    siblings () {
      const entries = this.$store.state.collection
      const i = entries.findIndex(entry => entry.slug === this.slug)
      if (i > -1) {
        const prev = entries[i - 1]?.slug
        const next = entries[i + 1]?.slug
        const first = entries[0].slug
        const last = entries[entries.length - 1].slug
        return [prev, next, first, last]
      }
      return []
    },
    uiColor () {
      return this.slides[this.current]?.captionColor || 'black'
    }
  },
  methods: {
    next () {
      if (this.current < this.slides.length - 1) {
        // next slide
        this.current++
      } else {
        const nextOrFirst = this.siblings[1] || this.siblings[2] // next | first
        // next item or loop to beginning !
        return this.$router.replace({ name: 'collection-item', params: { collectionItem: nextOrFirst } })
      }
    },
    prev () {
      if (this.current > 0) {
        // prev slide
        this.current--
      } else {
        const prevOrLast = this.siblings[0] || this.siblings[3]
        // prev item !
        return this.$router.replace({ name: 'collection-item', params: { collectionItem: prevOrLast } })
      }
    },
    close () {
      return lastRt?.name ? this.$router.go(-1) : this.$router.push({ name: 'collection' })
    },
    onKeydown: throttle(function (e) {
      switch (e.keyCode) {
        // ESC
        case 27: return this.close()
        case 39: return this.next()
        case 37: return this.prev()
      }
    }, 500)
  },
  // beforeRouteEnter (to, from , next) {
  //   lastRt = from
  //   next()
  // },
  mounted () {
    window.addEventListener('keydown', this.onKeydown)
  },
  destroyed () {
    window.removeEventListener('keydown', this.onKeydown)
  },
  metaInfo () {
    if (this.entry) {
      const title = `${this.entry.artist}, "${this.entry.title}", ${this.entry.year} | Collection`
      const img = this.slides[0]?.image[0].url || this.indexImage[0]?.url
      return {
        title,
        meta: this.$store.getters.meta({
          title,
          descrip: this.entry.metaDescription,
          img
        })
      }
    }
  },
  components: { CollectionItemSlide }
}
</script>

<style>
</style>
